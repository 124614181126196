
import React from "react";
import { Icon } from '@iconify/react';

const AppIcon = (props) => {

    const { icon } = props;

    return (
        <>
            <Icon icon={icon} />
        </>
    )
}

export default AppIcon;
