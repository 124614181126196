import {
    GET,
    POST,
    DELETE,
    PATCH,
    API_INVOCATION
} from "../../actionType";
import * as constdata from '../../../Utils/constants';
import * as consturl from '../../../url.js';
import * as token from '../../../Utils/token';

export const _list = (endpoint, _payload, resolve, reject, isLoader = true) => {

    const url = `${consturl.BASE_URL.API}${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: GET,
        isLoader,
        method: constdata.GET,
        apiConfig: {
            headers: { ...constdata.HEADERS, ...authToken },
            isLoader
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _get = (endpoint, _payload, resolve, reject, isLoader = true) => {

    const url = `${consturl.BASE_URL.API}${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: GET,
        isLoader,
        method: constdata.GET,
        apiConfig: {
            headers: { ...constdata.HEADERS, ...authToken },
            isLoader
        },
        url: url,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _post = (endpoint, _payload, resolve, reject, isLoader = true) => {

    const url = `${consturl.BASE_URL.API}${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: POST,
        isLoader,
        method: constdata.POST,
        apiConfig: {
            headers: { ...constdata.HEADERS, ...authToken },
            isLoader
        },
        url: url,
        data: _payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _put = (endpoint, _payload, resolve, reject, isLoader = true) => {

    const url = `${consturl.BASE_URL.API}${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: POST,
        isLoader,
        method: constdata.PUT,
        apiConfig: {
            headers: { ...constdata.HEADERS, ...authToken },
            isLoader
        },
        url: url,
        data: _payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _patch = (endpoint, _payload, resolve, reject, isLoader = true) => {

    const url = `${consturl.BASE_URL.API}${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: PATCH,
        isLoader,
        method: constdata.PATCH,
        apiConfig: {
            headers: { ...constdata.HEADERS, ...authToken },
            isLoader
        },
        url: url,
        data: _payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}

export const _delete = (endpoint, _payload, resolve, reject, isLoader = true) => {

    const url = `${consturl.BASE_URL.API}${endpoint}`;
    const authToken = token.authToken();
    const payload = {
        action: DELETE,
        isLoader,
        method: constdata.DELETE,
        apiConfig: {
            headers: { ...constdata.HEADERS, ...authToken },
            isLoader
        },
        url: url,
        //data:_payload,
        resolve,
        reject
    };
    return { type: API_INVOCATION, payload };
}