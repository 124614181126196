/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthenticatedLayout from "../Layouts/Authenticated";
import GuestLayout from "../Layouts/Guest";
import { getToken } from "../Utils/token";
import { MENU_LIST_NAMES } from "../Utils";

const routes = [

  {
    route: "*",
    component: GuestLayout,

    routes: [
      {
        path: MENU_LIST_NAMES.HOME.URL,
        menuName: MENU_LIST_NAMES.HOME.NAME,
        name: "HOME",
        exact: true,
        component: lazy(() => import("../View/pre-login/Login")),
        isPrivate: false,

      },
      {
        path: MENU_LIST_NAMES.LOGIN.URL,
        menuName: MENU_LIST_NAMES.LOGIN.NAME,
        name: "LOGIN",
        exact: true,
        component: lazy(() => import("../View/pre-login/Login")),
        isPrivate: false,

      },
      {
        path: MENU_LIST_NAMES.TASKS.URL,
        menuName: MENU_LIST_NAMES.TASKS.NAME,
        name: "TASKS",
        exact: true,
        component: lazy(() => import("../View/post-login/Tasks/Listing")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: "/task/:type",
        menuName: MENU_LIST_NAMES.TASKS_MY.NAME,
        name: "TASKS_MY",
        exact: true,
        component: lazy(() => import("../View/post-login/Tasks/Listing")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.TASK_DETAIL.URL,
        menuName: MENU_LIST_NAMES.TASK_DETAIL.NAME,
        name: "TASK_DETAIL",
        exact: true,
        component: lazy(() => import("../View/post-login/Tasks/Details")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.TASK_DETAIL_MY.URL,
        menuName: MENU_LIST_NAMES.TASK_DETAIL_MY.NAME,
        name: "TASK_DETAIL",
        exact: true,
        component: lazy(() => import("../View/post-login/Tasks/Details")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.USER_MGMT.URL,
        menuName: MENU_LIST_NAMES.USER_MGMT.NAME,
        name: "USER_MGMT",
        exact: true,
        component: lazy(() => import("../View/post-login/UserManagement/UsersDashboard")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.ROLE_MGMT.URL,
        menuName: MENU_LIST_NAMES.ROLE_MGMT.NAME,
        name: "ROLE_MGMT",
        exact: true,
        component: lazy(() => import("../View/post-login/UserManagement/UserRoleManagement")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.USER_MENU.URL,
        menuName: MENU_LIST_NAMES.USER_MENU.NAME,
        name: "USER_MENU",
        exact: true,
        component: lazy(() => import("../View/post-login/UserManagement/UserMenuManagement")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.EXC_LIST.URL,
        menuName: MENU_LIST_NAMES.EXC_LIST.NAME,
        name: "EXC_LIST",
        exact: true,
        component: lazy(() => import("../View/post-login/Exceptions/ExceptionListing")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.REPORT_1.URL,
        menuName: MENU_LIST_NAMES.REPORT_1.NAME,
        name: "REPORT V1",
        exact: true,
        component: lazy(() => import("../View/post-login/Reports/Static")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.REPORT_DASHBOARD.URL,
        menuName: MENU_LIST_NAMES.REPORT_DASHBOARD.NAME,
        name: "REPORT_DASHBOARD",
        exact: true,
        component: lazy(() => import("../View/post-login/Reports/Dashboard")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },
      {
        path: MENU_LIST_NAMES.ASSIGNMENT_LISTING.URL,
        menuName: MENU_LIST_NAMES.ASSIGNMENT_LISTING.NAME,
        name: "ASSIGNMENT_LISTING",
        exact: true,
        component: lazy(() => import("../View/post-login/Assignment/AssignmentListing")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },

      {
        path: MENU_LIST_NAMES.ADMIN_CONF.URL,
        menuName: MENU_LIST_NAMES.ADMIN_CONF.NAME,
        name: "ADMIN_CONF",
        exact: true,
        component: lazy(() => import("../View/post-login/Admin/Config")),
        isPrivate: false,
        isAuth: false,
        isMobileNav: true,
        params: {
          title: '',
        }
      },

      // {
      //   path: "/task",
      //   exact: false,
      //   component: AuthenticatedLayout,
      //   isPrivate: false,
      //   isAuth: true,
      //   isMobileNav: false,
      //   params: {
      //     title: '',
      //   },
      //   routes: [
      //     {
      //       path: "/task/listing",
      //       exact: true,
      //       component: lazy(() => import("../View/post-login/Tasks/Listing")),
      //       isPrivate: false,
      //       isAuth: true,
      //       isMobileNav: true,
      //       params: {
      //         title: '',
      //       }
      //     },
      //     {
      //       path: "/task/detail/:taskId",
      //       exact: true,
      //       component: lazy(() => import("../View/post-login/Tasks/Details")),
      //       isPrivate: false,
      //       isAuth: true,
      //       isMobileNav: true,
      //       params: {
      //         title: '',
      //       }
      //     },
      //   ],
      // },
      {
        path: "/not-found",
        exact: true,
        component: lazy(() => import("../Layouts/NotFound/NotFound")),
        isPrivate: true,
      },

      // {
      //   component: () => <Redirect to="/not-found" />
      // },
    ],

  },

];

export default routes;
