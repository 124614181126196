import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import Sidebar from "./Components/Sidebar";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import Header from "../Guest/Components/Header/Header";
import './../../Scss/style.scss';

const Loader = () => (
  <></>
)

const AuthenticatedLayout = (props) => {
  // console.log(props);
  const { t } = useTranslation();

  const { route } = props;
  const location = useLocation();



  return (
    <>

      <div className="main-wrapper">
        <Header {...props}/>
        <div className="article">
          <Sidebar {...props}/>
          <Suspense
            fallback={<Loader />}
          >

            {renderRoutes(route.routes)}

          </Suspense >
        </div>
      </div>

    </>
  );
};

export default AuthenticatedLayout;
