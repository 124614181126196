export const setToken = (access_token) => {
  let token = window.localStorage.setItem("access_token", access_token);
  return token;
};

export const getToken = () => {
  let token = window.localStorage.getItem("access_token");
  return token;
};

export const authToken = () => {
  return null;
  return {
    "Authorization": `Bearer ${getToken()}`
  }
};


export const parseToken = (_token = null) => {
  const token = _token || window.localStorage.getItem("access_token");
  if (!token) {
    return { user_name: "" };
  }
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}