//import * as constants from './Utils/constants';

//import masterTranslation from './master.translation.json';


const delay = ms => new Promise(res => setTimeout(res, ms));

const beforeInit = async (translations, lang) => {
    // const data = localStorage.getItem(constants.LOCALSTORAGE.translations);
    // if(!localStorage.getItem(constants.LOCALSTORAGE.translations)){
    //     localStorage.setItem(constants.LOCALSTORAGE.translations,JSON.stringify(masterTranslation));
    //     window.location.reload();
    // }
    // if(!localStorage.getItem(constants.LOCALSTORAGE.lang)){
    //     localStorage.setItem(constants.LOCALSTORAGE.lang,constants.FALLBACK_LANG);
    // }

    await delay(1000);
    //localStorage.setItem(constants.LOCALSTORAGE.translations, JSON.stringify(masterTranslation));
    if (!lang || lang === "null") {
        //localStorage.setItem(constants.LOCALSTORAGE.lang, constants.FALLBACK_LANG);
        //window.location.reload();
    }
    await delay(1000);
};

export default beforeInit;
