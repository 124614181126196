import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AppIcon from "../../../../Components/Icon";
import { MENU_LIST_NAMES, _set, isMenuAllowed, menuList } from "../../../../Utils";

const Sidebar = (props) => {

  const { t } = useTranslation();
  const location = useLocation();
  const channel = new BroadcastChannel("menu");

  const onCreateManualTaskClick = (event) => {
    props.history.push(`/task/manual`);
    window.addEventListener("click", (event) => {
      channel.postMessage("manual");
    });
  }

  const activeMenu = (str) => {
    if (window.location.href.indexOf(str) !== -1) {
      return "active"
    }
    return "";
  }

  const confirmCancel = () => {
    let text = "Are you sure you want to leave.";
    if (window.confirm(text) == true) {
      return true;
    } else {
      return false;
    }
  }

  const currentMenu = () => {
    if (window.location.href.indexOf('task/detail') !== -1) {
      return confirmCancel();
    } else {
      return true;
    }
  }

  const saveRouteName = (name) => {
    _set("props.route.name", name);
  }

  const history = {
    push: (data) => {
      return (currentMenu()) ? props.history.push(data) : false;
    }
  }

  return (
    <>
      <div className="left-sidebar">
        <ul className="top-menu-items">

          {isMenuAllowed(MENU_LIST_NAMES.TASKS.NAME) &&
            <li className={activeMenu(MENU_LIST_NAMES.TASKS.URL) || activeMenu(`task/detail`)}>
              <div className="menu-box" onClick={() => { saveRouteName("TASKS"); history.push(MENU_LIST_NAMES.TASKS.URL) }}>
                <AppIcon icon="fe:list-bullet" />
                <h3>{MENU_LIST_NAMES.TASKS.NAME}</h3>
              </div>
            </li>
          }
          {isMenuAllowed(MENU_LIST_NAMES.TASKS_MY.NAME) &&
            <li className={activeMenu(MENU_LIST_NAMES.TASKS_MY.URL) || activeMenu(`task/my/detail`)}>
              <div className="menu-box" onClick={() => { saveRouteName("TASKS_MY"); history.push(MENU_LIST_NAMES.TASKS_MY.URL) }}>
                <AppIcon icon="ph:envelope-open-light" />
                <h3>{MENU_LIST_NAMES.TASKS_MY.NAME}</h3>
              </div>
            </li>
          }
          {isMenuAllowed(MENU_LIST_NAMES.EXC_LIST.NAME) &&
            <li className={activeMenu(MENU_LIST_NAMES.EXC_LIST.URL)}>
              <div className="menu-box" onClick={() => history.push(MENU_LIST_NAMES.EXC_LIST.URL)}>
                <AppIcon icon="ant-design:exception-outlined" />
                <h3>{MENU_LIST_NAMES.EXC_LIST.NAME}</h3>
              </div>
            </li>
          }

          {isMenuAllowed(MENU_LIST_NAMES.REPORT_DASHBOARD.NAME) &&
            <li className={activeMenu(`report/`)}>
              <div className="menu-box" onClick={() => history.push(MENU_LIST_NAMES.REPORT_DASHBOARD.URL)}>
                <AppIcon icon="mdi:file-report-outline" />
                <h3>Reports</h3>
              </div>
            </li>
          }
          {isMenuAllowed(MENU_LIST_NAMES.TAKS_MANUAL.NAME) &&
            <li className={activeMenu(MENU_LIST_NAMES.TAKS_MANUAL.URL)} onClick={onCreateManualTaskClick}>
              <div className="menu-box">
                <AppIcon icon="material-symbols:format-list-bulleted-add-rounded" />
                <h3>{MENU_LIST_NAMES.TAKS_MANUAL.NAME}</h3>
              </div>
            </li>
          }
          {isMenuAllowed(MENU_LIST_NAMES.ERROR_LOGS.NAME) &&
            <li className={activeMenu(MENU_LIST_NAMES.ERROR_LOGS.URL)}>
              <div className="menu-box">
                <AppIcon icon="material-symbols:error-rounded" />
                <h3>{MENU_LIST_NAMES.ERROR_LOGS.NAME}</h3>
              </div>
            </li>
          }
        </ul>

        <ul className="bottom-menu-items">
          {isMenuAllowed(MENU_LIST_NAMES.ADMIN_CONF.NAME) &&
            <li onClick={() => props.history.push(MENU_LIST_NAMES.ADMIN_CONF.URL)}><AppIcon icon="icon-park:setting-config" />{MENU_LIST_NAMES.ADMIN_CONF.NAME}</li>
          }

          {/* {isMenuAllowed(MENU_LIST_NAMES.ASSIGNMENT_LISTING.NAME) &&
            <li onClick={() => props.history.push(MENU_LIST_NAMES.ASSIGNMENT_LISTING.URL)}><AppIcon icon="icon-park:setting-config" />{MENU_LIST_NAMES.ASSIGNMENT_LISTING.NAME}</li>
          } */}

          {isMenuAllowed(MENU_LIST_NAMES.USER_MGMT.NAME) &&
            <li onClick={() => props.history.push(MENU_LIST_NAMES.USER_MGMT.URL)}><AppIcon icon="el:cogs" />{MENU_LIST_NAMES.USER_MGMT.NAME}</li>
          }
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
