import axios from 'axios';
import { publish } from './events';

const configuration = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': [
      'Origin', 'Accept', 'X-Requested-With', 'Content-Type', 'Authorization'
    ]
  }
};

const instance = axios.create({ configuration });

var numberOfAjaxCAllPending = 0;

instance.interceptors.request.use((config) => {
  // const oAuthTokan = localStorage.getItem('token') || null;
  // const token = getToken() || null;
  if(config.isLoader){
    numberOfAjaxCAllPending++;
  }
  const token = null;

  let newHeaders = config.headers;
  if (token) {
    newHeaders = Object.assign({}, newHeaders, { Authorization: `Bearer ${token}` });
  }
  if(config.isLoader){
    publish('pendingRequests',numberOfAjaxCAllPending);
  }
  return Object.assign({}, config, { headers: newHeaders });
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response)=>{
  numberOfAjaxCAllPending--;
  //console.log("------------  Ajax pending", numberOfAjaxCAllPending);

  if (numberOfAjaxCAllPending <= 0) {
      //hide loader
      publish('pendingRequests',0);
  }
  return response;
}, (error)=> {
  numberOfAjaxCAllPending--;
  if (numberOfAjaxCAllPending <= 0) {
      //hide loader
      publish('pendingRequests',0);
  }
  return Promise.reject(error);
});

const getAxios = () => instance;

export default getAxios;