import { parseToken } from "./token";

export const sleep = (ms) => new Promise(r => setTimeout(r, ms));

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

export const menuList = () => {
    return JSON.parse(localStorage.getItem('menuList'));
}

export const disableBtns = () => {
    document.querySelectorAll('.task-detail-btn').forEach(e => {
        e.classList.add('pointer-events-none');
    })
}


export const enableBtns = () => {
    document.querySelectorAll('.task-detail-btn').forEach(e => {
        e.classList.remove('pointer-events-none');
    })
}


export const _get = (attr, isJSON = false, _return = null) => {
    const storage = sessionStorage.getItem("app_" + attr);
    if (storage && isJSON) {
        return JSON.parse(storage);
    }

    if (_return && !storage) {
        return _return;
    }

    return storage;
}

export const _set = (attr, value, isJSON = false) => {
    sessionStorage.removeItem("app_" + attr);
    setTimeout(() => {
        value = (isJSON) ? JSON.stringify(value) : value;
        sessionStorage.setItem("app_" + attr, value);
    }, 100);


    return true;
}

export const isMenuPermAllowed_outside_consultant = (props) => {
    const outside_consultant = window.localStorage.getItem("roleName");
    const is_outside_consultant = 
    (outside_consultant === "Outside Consultant" || 
    outside_consultant === "Purchasing"
    ) ? true : false;
    if (is_outside_consultant) {
        return false;
    } else {
        return true;
    }
}

export const isMenuPermAllowed_outside_consultant_clause = (props) => {
    const role_name = window.localStorage.getItem("roleName");
    const is_outside_consultant =  (role_name === "Outside Consultant") ? true : false;
    const is_purchasing =  (role_name === "Purchasing") ? true : false;
    
    if(is_purchasing){
        return true;
    }

    if (is_outside_consultant) {
        return false;
    } else {
        return true;
    }
}

export const isRolePurchasing=()=>{
    const outside_consultant = window.localStorage.getItem("roleName");
    if(outside_consultant == "Purchasing"){
        return true;
    }
    return false;
}


export const isMenuPermAllowed_view = (props) => {
    const hasPermission = isMenuPermAllowed(MENU_LIST_NAMES[props.route.name].NAME, ["view"]);
    return hasPermission ? true : false;
}

export const isMenuPermAllowed_add = (props) => {
    const hasPermission = isMenuPermAllowed(MENU_LIST_NAMES[props.route.name].NAME, ["add"]);
    return hasPermission ? true : false;
}

export const isMenuPermAllowed_add_update = (props) => {
    const hasPermission = isMenuPermAllowed(MENU_LIST_NAMES[props.route.name].NAME, ["add", "update"]);
    return hasPermission ? true : false;
}

export const isMenuPermAllowed_delete = (props) => {
    const hasPermission = isMenuPermAllowed(MENU_LIST_NAMES[props.route.name].NAME, ["delete"]);
    return hasPermission ? true : false;
}

export const isMenuAllowed = (menuName) => {

    return isMenuPermAllowed(menuName, ["view"])
}

export const isSubMenuAllowed = (menuName, submenuName) => {

    return isSubMenuPermAllowed(menuName, submenuName, ["view"])
}

export const isSubMenuAllowed_add = (menuName, submenuName) => {

    return isSubMenuPermAllowed(menuName, submenuName, ["add"])
}

export const isSubMenuAllowed_update = (menuName, submenuName) => {

    return isSubMenuPermAllowed(menuName, submenuName, ["update"])
}

export const isSubMenuAllowed_add_update = (menuName, submenuName) => {

    return isSubMenuPermAllowed(menuName, submenuName, ["add", "update"])
}

export const isSubMenuAllowed_delete = (menuName, submenuName) => {

    return isSubMenuPermAllowed(menuName, submenuName, ["delete"])
}


export const isMenuPermAllowed = (menuName, permissions) => {

    const MENU_LIST = menuList();

    if (MENU_LIST &&
        MENU_LIST.length > 0 &&
        MENU_LIST.find(e => e.menuName === menuName && e.permission.some(f => permissions.includes(f)))
    ) {
        return true;
    }

    return false;
}

export const isSubMenuPermAllowed = (menuName, submenuName, permissions) => {

    const MENU_LIST = menuList();

    if (MENU_LIST &&
        MENU_LIST.length > 0
    ) {
        const SUB_MENUS = MENU_LIST.find(e => e.menuName === menuName);
        const SUB_MENU_LIST = SUB_MENUS?.subMenu;
        if (SUB_MENU_LIST && SUB_MENU_LIST.find(e => e.menuName === submenuName && e.permission.some(f => permissions.includes(f)))) {
            return true;
        }

    }

    return false;
}

export const MENU_LIST_NAMES = {
    ADMIN_CONF: {
        NAME: "Admin Configuration",
        URL: "/admin/config"
    },
    USER_MGMT: {
        NAME: "User Settings",
        URL: "/user/management"
    },
    TASKS: {
        NAME: "All Tasks",
        URL: "/task/listing"
    },
    TASK_DETAIL: {
        NAME: "Task Detail",
        URL: "/task/detail/:taskId"
    },
    TASK_DETAIL_MY: {
        NAME: "Task Detail",
        URL: "/task/:type/detail/:taskId"
    },
    TASK_DETAIL_CORRECTION: {
        NAME: "Invoice Correction",
        URL: "/task/detail/:taskId"
    },
    TASK_DETAIL_MATCHING: {
        NAME: "Invoice Matching",
        URL: "/task/detail/:taskId"
    },
    TASKS_MY: {
        NAME: "My Tasks",
        URL: "/task/my"
    },
    TAKS_MANUAL: {
        NAME: "Create Manual Task",
        URL: "/task/manual"
    },
    EXC_LIST: {
        NAME: "Exception List",
        URL: "/exception/listing"
    },
    ERROR_LOGS: {
        NAME: "Error Logs",
        URL: "/error/listing"
    },
    ROLE_MGMT: {
        NAME: "Role Settings",
        URL: "/user/roles"
    },
    USER_MENU: {
        NAME: "User Menu",
        URL: "/user/menu"
    },
    REPORT_DASHBOARD: {
        NAME: "Report Dashboard",
        URL: "/report/dashboard"
    },
    ASSIGNMENT_LISTING: {
        NAME: "Assignment Listing",
        URL: "/assignment/listing"
    },
    REPORT_1: {
        NAME: "Unsubmitted Report",
        URL: "/report/unsubmitted"
    },
    LOGIN: {
        NAME: "Login",
        URL: "/login"
    },
    HOME: {
        NAME: "Login",
        URL: "/"
    }
}