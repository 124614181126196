import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import * as constants from './Utils/constants';

const languages = {
    "en": "en",
    "en-us": "en",
    "no": "no",
    "hi": "hi",
};

//const translations = JSON.parse(window.localStorage.getItem(constants.LOCALSTORAGE.translations)) || null;

const browser_lang = navigator.language || navigator.userLanguage;

const get_lang = languages[browser_lang];

const user_lang = window.localStorage.getItem(constants.LOCALSTORAGE.lang) || get_lang || "en";

i18n
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // connect with React
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,

        lng: user_lang,
        fallbackLng: 'en',
        whitelist: ['en', 'fr', 'no'],

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        //resources: translations,
        backend: {
            loadPath: `/locales/${user_lang}/translation.json`,
        },
    });

export default i18n;