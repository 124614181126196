import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider as StoreProvider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import beforeInit from './beforeInit';
import Store from "./Store/index";
import * as constants from './Utils/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import './Firefox.scss';
import '../node_modules/jquery/dist/jquery.js';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// import '../node_modules/popper.js/dist/popper.js';
// import '../node_modules/font-awesome/css/font-awesome.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './i18n';

const translations = localStorage.getItem(constants.LOCALSTORAGE.translations);
const lang = localStorage.getItem(constants.LOCALSTORAGE.lang);
beforeInit(translations, lang);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={Store}>
      <Suspense fallback={null}>
        <App />
         <ToastContainer autoClose={4000}/>
      </Suspense>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();