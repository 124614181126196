import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import AppIcon from "../../../../Components/Icon";
import { parseToken } from "../../../../Utils/token";

//import * as constants from './../../../../Utils/constants';

const Header = (props) => {

  //console.log(props);

  const { t } = useTranslation();

  const [time, settime] = useState('');
  const [roleName,setroleName]=useState(localStorage.getItem("roleName"));

  const username = parseToken().user_name ? parseToken().user_name : "Guest User";

  const formatAMPM = (date = new Date()) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ":" +seconds + ' ' + ampm;
    settime(strTime);

    // setInterval(()=>{
    //     formatAMPM();
    // },400);
  }

  useEffect(() => {
    formatAMPM();
  }, []);

  const onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    props.history.push('/')
  }

  return (
    <div className="header-top">
      <div className="logo-section">
        <img src="/assets/images/Phoenix-logo.svg" alt="" />
      </div>

      <div className="nav-section">
        <div className="nav-items">

        </div>
        {/* <div style={{ textAlign: 'right' }}>
                    <div className="userName font14"> User: Ming Zhou</div>
                    <div className="userName font14">Time: {time}</div>
                </div> */}

        <div className="logged-user-dtls dropdown">
          <div className="userDtls">
            <div className="userName font14"> <span className="usercolor"> User :</span>  <b>{username} ({roleName})</b></div>
            {/* <div className="userName font14"><span className="usercolor"> Role :</span>  <b>{roleName}</b></div> */}
            <div className="userName font14"> <span className="usercolor">  Time: </span> <b> {time}</b></div>
            
            {/* <div className="loggedTime font14">Last login time : 08:00 AM</div> */}
          </div>
          <div className="userAvatar dropdown-toggle pointer" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
            <AppIcon icon="carbon:user-avatar" />

          </div>

          <ul className="dropdown-menu p-0" aria-labelledby="filter1">
            <li ><a className="dropdown-item py-2 pointer font14" onClick={() => onLogout()}>Logout</a></li>
          </ul>

          {/* <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle text-start" type="button" id="filter1" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            Company Code
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="filter1">
                            <div className="options-group">
                                <div className="mb-3 form-check">
                                    Logout
                                </div>
                            </div>
                        </ul>
                    </div> */}

        </div>
      </div>
    </div>

  );
};

export default Header;
