export const API_INVOCATION = "API_INVOCATION";

export const LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD";
export const LOGIN_WITH_PASSWORD_SUCCESS="LOGIN_WITH_PASSWORD_SUCCESS";
export const LOGIN_WITH_PASSWORD_ERROR="LOGIN_WITH_PASSWORD_ERROR";

export const SHOW_TOAST_MESSAGE="SHOW_TOAST_MESSAGE";
export const RESET_SHOW_TOAST_MESSAGE="RESET_SHOW_TOAST_MESSAGE";
export const START_LOADER="START_LOADER";
export const STOP_LOADER="STOP_LOADER";

export const GET="GET";
export const GET_SUCCESS="GET_SUCCESS";
export const GET_ERROR="GET_ERROR";

export const POST="POST";
export const POST_SUCCESS="POST_SUCCESS";
export const POST_ERROR="POST_ERROR";

export const PATCH="PATCH";
export const PATCH_SUCCESS="POST_SUCCESS";
export const PATCH_ERROR="POST_ERROR";

export const DELETE="DELETE";
export const DELETE_SUCCESS="DELETE_SUCCESS";
export const DELETE_ERROR="DELETE_ERROR";









